import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoDark from "../assets/logos/logoDark.svg";

import "./Navbar.module.css";

const Navigation: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();

  const toggleActive = () => {
    console.log("gets called");
    setIsActive(!isActive);
  };

  const isActiveLink = (path: string) => {
    return location.pathname === path ? "bg-yellow-200" : "";
  };

  return (
    <nav className="navigation flex justify-between items-center p-4 relative z-10">
      <Link
        to="/"
        className="logo flex items-centmd font-normal text-gray-900 pl-3 pt-3"
        style={{
          zIndex: 9999,
          color: "black",
          fontFamily: "Chivo",
          textDecoration: "none",
        }}
      >
        <img
          src={logoDark}
          alt="logotype"
          className="h-16 w-16  md:block hidden"
        />
        <div className="flex flex-col">
          <h1 className="text-3xl m-0 md:ml-3 mt-1">Robin Lundin</h1>
          <h2 className="text-lg font-light m-0 md:ml-3">Design & Animation</h2>
        </div>
      </Link>

      {!isActive ? (
        <ul
          style={{ fontFamily: "Poppins" }}
          className={`menu flex justify-center items-center gap-4 pr-12 ${
            isActive ? "block" : "hidden"
          } md:flex`}
        >
          <li className={` ${isActiveLink("/animation")} `}>
            <Link
              to="/animation"
              className={`text-md font-normal p-1 px-3 transition-colors duration-200 }`}
            >
              Animation
            </Link>
          </li>

          <li>
            <Link
              to="/halsokuppen"
              className={`text-md font-normal p-1 px-3 transition-colors duration-200 ${isActiveLink(
                "/halsokuppen"
              )}`}
            >
              Hälsokuppen
            </Link>
          </li>

          <li>
            <Link
              to="/about"
              className={`text-md font-normal p-1 px-3 transition-colors duration-200 ${isActiveLink(
                "/about"
              )}`}
            >
              About
            </Link>
          </li>
        </ul>
      ) : (
        <ul
          className={`menu flex flex-col justify-center items-center bg-slate-100 fixed inset-0 gap-12 ${
            isActive ? "block" : "hidden"
          } md:flex`}
          style={{ zIndex: 9998 }}
        >
          <li>
            <Link
              to="/animation"
              className={`text-4xl font-bold p-3 transition-colors duration-200`}
              onClick={toggleActive}
            >
              Animation
            </Link>
          </li>

          <li>
            <Link
              to="/halsokuppen"
              className={`text-4xl font-bold p-3 transition-colors duration-200`}
              onClick={toggleActive}
            >
              Hälsokuppen
            </Link>
          </li>

          <li>
            <Link
              to="/about"
              className={`text-4xl font-bold p-3 transition-colors duration-200`}
              onClick={toggleActive}
            >
              About
            </Link>
          </li>
        </ul>
      )}

      <div
        className={`tham tham-e-squeeze tham-w-10 md:hidden ${
          isActive ? "tham-active" : ""
        }`}
        style={{ zIndex: 9999, position: "absolute", top: 36, right: 24 }}
        onClick={toggleActive}
      >
        <div className="tham-box">
          <div className="tham-inner" />
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
