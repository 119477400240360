import React from "react";
import { Navigate } from "react-router";
import { Route, Routes } from "react-router-dom";

// import Landing from "../pages/Landing";
import Animation from "../pages/Animation";
import Halsokuppen from "../pages/Halsokuppen";
import About from "../pages/About";

interface AppRoutesProps {}

const AppRoutes: React.FC<AppRoutesProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/animation" replace />} />
      <Route path="*" element={<Navigate to="/animation" replace />} />
      <Route path="/animation" element={<Animation />} />
      <Route path="/halsokuppen" element={<Halsokuppen />} />
      <Route path="/about" element={<About />} />
    </Routes>
  );
};

export default AppRoutes;
