import React from "react";
import { motion } from "framer-motion";

interface Props {
  // Define your component's props here
}

const About: React.FC<Props> = (props) => {
  const container = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 2 } },
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen -mt-28 md:-mt-24 p-12">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="max-w-3xl"
      >
        <motion.h1 className="text-4xl font-bold">Hi,</motion.h1>
        <motion.p className="mt-4">
          My name is Robin and i'm a digital designer and developer based in
          Växjö, Sweden.
          <br />
          <br />
          As a generalist within IT my experience spans from front-end
          development to UX, UI, Motion, 3D and Graphic design. Throughout my
          career, I've occupied various roles such as designer, developer,
          teacher, and product owner, accumulating extensive knowledge and
          experience.
          <br />
          <br /> I'm always open to fun ideas and projects, and I would love to
          hear from you if you have any. You can reach me at{" "}
          <a
            href="mailto:robin.lundin@protonmail.com"
            style={{ color: "#0078c8", textDecoration: "underline" }}
          >
            robin.lundin@protonmail.com
          </a>{" "}
          or through any social media links found at the bottom of the page.
        </motion.p>
      </motion.div>
    </div>
  );
};

export default About;
