import React from "react";
import { motion } from "framer-motion";
import screen1 from "../assets/media/imgs/screen1.png";
import screen2 from "../assets/media/imgs/screen2.png";
import screen3 from "../assets/media/imgs/screen3.png";
import broccoflat from "../assets/media/imgs/broccoflat.svg";

interface Props {
  // Define your component's props here
}

const Halsokuppen: React.FC<Props> = (props) => {
  const container = {
    hidden: { opacity: 0 },
    show: { opacity: 1, transition: { duration: 2 } },
  };

  return (
    <div className="flex flex-col items-center justify-center md:h-screen  md:-mt-24 md:p-12 p-8 gap-16 md:flex-row">
      <motion.div
        variants={container}
        initial="hidden"
        animate="show"
        className="flex flex-col max-w-3xl"
      >
        <motion.div
          initial="hidden"
          animate="show"
          className="flex gap-2 items-center"
        >
          <motion.img
            src={broccoflat}
            alt="Hälsokuppen"
            width={42}
            height={42}
          />
          <motion.h1 className="text-4xl font-bold">Hälsokuppen</motion.h1>
        </motion.div>
        <motion.p className="mt-4">
          Hälsokuppen is an inclusive and holistic health challenge. It has been
          my passion project since 2018 and has served as a tool I've used with
          friends, family, and companies where I've worked and consulted. At one
          point, we had around 100 participants competing together in a friendly
          manner.
          <br />
          <br />
          The project started as a response to the traditional approach of just
          working out as much as possible. I wanted Hälsokuppen to offer a more
          inclusive and holistic approach to health and wellness. The
          application is a health and wellness platform that helps users track
          their daily activities, food intake, exercise, and more.
          <br />
          <br />
          The project is built for both mobile and desktop using web
          technologies (a so-called PWA - Progressive Web Application), and I am
          currently working on deployment to the app stores.
          <br />
          <br />
          Feel free to try it out at{" "}
          <a
            href="https://halsokuppen.se"
            style={{ color: "#0078c8", textDecoration: "underline" }}
          >
            Hälsokuppen
          </a>{" "}
        </motion.p>
      </motion.div>

      <motion.div className="flex flex-col gap-4 md:flex-row">
        <img
          src={screen1}
          alt="Hälsokuppen"
          width={240}
          style={{ border: "1px solid #010101", borderRadius: 8 }}
        />
        <img
          src={screen2}
          alt="Hälsokuppen"
          width={240}
          style={{ border: "1px solid #010101", borderRadius: 8 }}
        />
        <img
          src={screen3}
          alt="Hälsokuppen"
          width={240}
          style={{ border: "1px solid #010101", borderRadius: 8 }}
        />
      </motion.div>
    </div>
  );
};

export default Halsokuppen;
