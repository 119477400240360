import React from "react";

import "./App.css";
import Navbar from "./components/Navbar";
import AppRoutes from "./routing/AppRoutes";
import Footer from "./components/Footer";

function App() {
  // const [isDarkMode, setIsDarkMode] = useState<boolean>(
  //   localStorage.getItem("isDarkMode") === "true"
  // );

  return (
    <>
      <Navbar />
      <AppRoutes />
      <Footer />
    </>
  );
}

export default App;
